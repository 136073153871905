<template>
  <div>
    <div class="my-2">
      <SearchTextInput
        v-model="searchValue"
        label="Search Name"
        placeholder="Search"
        @keyup.enter.native="fetchSearch()" />
    </div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        class="my-1"
        @click="fetchSearch()"
      >
        <feather-icon icon="SearchIcon" />Search
      </b-button>
    </div>
  </div>
</template>
<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'

export default {
  components: {
    SearchTextInput
  },
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      perPageOptions: [10, 20, 50, 100, 500, 1000],
      isKnowledgeSelectedSideBarActive: false,
      selected: [],
      deleted: [],
      selectedAvalibelSave: [],
      refreshIsInCategory: true,
      filter: {
        searchCodeAndName: '',
        tags: []
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'selected', label: '' },
        { key: 'code', label: 'Code', sortable: true },
        { key: 'subject', label: ' Subject' },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' },
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
        { key: 'isInCategory', label: '' }
      ],
      items: [
      ],
      knowledge: {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: '',
        solutionFile: '',
        categoryId: ''
      },
      mockData: [
        { label: '#001', value: 0 },
        { label: '#002', value: 1 },
        { label: '#003', value: 2 },
        { label: '#004', value: 3 },
        { label: '#005', value: 4 }
      ],
      files: {
        problemFile: [],
        solutionFile: []
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      categories: [],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      options: [
        { text: 'Orange', value: 'orange' },
        { text: 'Apple', value: 'apple' },
        { text: 'Pineapple', value: 'pineapple' },
        { text: 'Grape', value: 'grape' }
      ]
    }
  },
  computed: {
    searchValue: {
      get () {
        return this.search
      },
      set (val) {
        return this.$emit('update:search', val)
      }
    }
  },
  mounted () {
  },
  methods: {
    fetchSearch () {
      const payload = {
        searchCodeAndName: this.filter.searchCodeAndName
      }
      if (!this.filter?.searchCodeAndName) {
        delete payload.searchCodeAndName
      }
      this.$emit('fetch-filter', payload)
    }
  }
}
</script>
<style scoped>
.clamped-text {
  width: 30px;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
</style>
