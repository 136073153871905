<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <EmailGroupFilter
      :search.sync="filter.searchCodeAndName"
      :status-filter.sync="filter.status"
      :search-tags.sync="filter.tags"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Email Group"
        to-path="/email-group/add"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)"
      >
        <template #cell(action)="{item}">
          <b-button
            size="sm"
            variant="danger"
            @click="deleteEmailGroup(item)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
        <template #cell(createdAt)="{item}">
          {{ item.createdAt | formatDateTimeEn }}
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TableTop from '@/components/TableTop.vue'
import EmailGroupProvider from '@/resources/EmailGroupProvider'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import EmailGroupFilter from './components/EmailGroupFilter.vue'

const EmailGroupService = new EmailGroupProvider()

export default {
  components: {
    TableTop,
    EmailGroupFilter,
    FeatherIcon
  },
  data () {
    return {
      filter: {
        searchCodeAndName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'name', label: 'Group Name' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'action', label: '' }
      ],
      items: [
      ]
    }
  },
  mounted () {
    this.getEmailGroup()
  },
  methods: {
    async getEmailGroup () {
      try {
        const data = await EmailGroupService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async deleteEmailGroup (item) {
      try {
        await EmailGroupService.softDelete(item?.id)
        // this.setPagination(1)
        this.getEmailGroup()
      } catch (error) {
        console.error(error)
      }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getEmailGroup()
    },
    rowClick (val) {
      this.$router.push(`/email-group/${val.id}`)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getEmailGroup()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
.wrap-text {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
   -webkit-box-orient: vertical;
}
</style>
